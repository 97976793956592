import { faCog, faMinusSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';
import TicketCounter from '../../../ui/Ticket/TicketCounter';
import React, { useEffect, useState } from 'react'
import CartItemSubTotal from './CartItemSubTotal';
import { useDispatch } from 'react-redux';
import { removeFromCart } from '../../../../service/actions/cartActions';
import { BACKEND_URL } from '../../../../APIConfig';
import { useTranslation } from 'react-i18next'

function CartItem(props) {
  const [suplement, setsuplement] = useState({})
  useEffect(() => {
  let supplement_selected=JSON.parse(JSON.stringify(props.ticket.selected_supplement));
  let supplies_added=[];
  Object.keys(supplement_selected).forEach((key)=>{
    supplies_added[(key-1)]=[];
    supplement_selected[key].forEach(item=>{   
      if((item.type==1 || item.type==3)&&item.value)  
      {
        supplies_added[(key-1)].push(item)
      }
      if(item.type==2 && item.value.length!=0) 
      {
        supplies_added[(key-1)].push(item)
      }
    })
  })
  setsuplement(supplies_added)

},[props])

  const removeItem = ()=>
  {
    removeLastFromLocalStorage("customer_name",props.event.id, props.ticket.id);
    removeLastFromLocalStorage("customer_phone",props.event.id, props.ticket.id);
    removeLastFromLocalStorage("customer_email",props.event.id, props.ticket.id)
    removeLastFromLocalStorage("customer_club",props.event.id, props.ticket.id);
    removeLastFromLocalStorage("customer_birthplace",props.event.id, props.ticket.id);
    removeLastFromLocalStorage("customer_birth",props.event.id, props.ticket.id);
    removeLastFromLocalStorage("customer_invoice",props.event.id, props.ticket.id);
    removeLastFromLocalStorage("customer_invoicedetail",props.event.id, props.ticket.id);
    dispatch(removeFromCart(props.event.id, props.ticket.id));
  }

  const removeLastFromLocalStorage = (key,eventid,ticketid)=> {
    let count = 1;
    const existing = localStorage.getItem(key);
    if (existing) {
        let items = JSON.parse(existing);
        while(`ticket_${eventid}_${ticketid}_${count}` in items)
        {
        delete items[`ticket_${eventid}_${ticketid}_${count}`]
        count+=1;
        }
        if (Object.keys(items).length > 0) {
            localStorage.setItem(key, JSON.stringify(items));
        } else {
            localStorage.removeItem(key); 
        }
    }
  }
  const dispatch = useDispatch();
  const {t, i18n} = useTranslation('common');
  return (
    <div className='cart_item'>
      <div className='row no-gutters'>
        <div className='col-7 col-md-7'>
          <a href={props.link}>
            <h6 className='title mb-2 notranslate'>{props.title}</h6>
          </a>
          {/* <p className='dlist-inline small'>{props.option}</p> */}
          <figure className='media cart_item-section'>
            <div className='img-wrap my-auto'>
              <img
                src={BACKEND_URL + props.image}
                className='img-thumbnail img-sm2'
              />
            </div>
            <figcaption className='media-body my-auto '>
              <dl className='dlist-inline small'>
                <dt className='mr-2'>{t('When')}</dt>
                <dd>{props.when}</dd>
              </dl>
              <dl className='dlist-inline small'>
                <dt className='mr-2'>{t('Where')}</dt>
                <dd className="notranslate">{props.where}</dd>
              </dl>
              <dl className='dlist-inline small'>
                <dt className='mr-2'>{t('Header.Organizer')}</dt>
                <dd className="notranslate">{props.orgName}</dd>
              </dl>
              <dl className='dlist-inline small'>
                <dt className='mr-2'>{t('Header.TicketTitle')}</dt>
                <dd>{props.ticket.title}</dd>
              </dl>
            </figcaption>
          </figure>
         
        </div>
        <div className='col-12 col-md-4' >
          <div className='cart_item-sec-mobile d-flex justify-content-between shopping-cart-wrap align-items-center'>
            <div className='price-wrap'>
              <span className='price-new'>
                <span className='text-muted font-weight-bold cart_item-sec-mobile-label d-block d-md-none mb-2 mt-3'>
                  {t('Price')}
                </span>
                €{props.price.toFixed(2).toString().replace('.', ',')}
              </span>
            </div>
            <div style={{ maxWidth: '80px', minWidth: '80px' }}>
              <span className='text-muted font-weight-bold cart_item-sec-mobile-label d-block d-md-none mb-2 mt-3'>
                {t('Quantity')}
              </span>
              <TicketCounter
                max={10}
                value={0}
                event={props.event}
                ticket={props.ticket}
                ticketLeftText={false}
                item={props.item}
                itemD = {props.itemD}
              />
            </div>
            <div className='price-wrap'>
              <span className='text-muted font-weight-bold cart_item-sec-mobile-label d-block d-md-none mb-2 mt-3'>
                {t('Subtotal')}
              </span>
              <var className='price notranslate'>€{props.ticket.subTotal.toString().replace('.', ',')}</var>
            </div>
            <div className='text-right'>
              <span className='text-muted font-weight-bold cart_item-sec-mobile-label d-block d-md-none mb-2 mt-3'>
                <FontAwesomeIcon icon={faCog} />
              </span>
              <span
                onClick={() =>
                  window.confirm(
                    t('AreYouSureRemoveCartItem')
                  ) && removeItem()
                }
                className='link-alt'
                data-tip
                data-for='remove-icon'>
                <FontAwesomeIcon icon={faMinusSquare} />
              </span>
              <ReactTooltip
                id='remove-icon'
                effect='solid'
                className='tooltip-inner'
                backgroundColor='#31c5f4'
                borderColor='#31c5f4'
                arrowColor='#31c5f4'>
                <span>{t('Remove')}</span>
              </ReactTooltip>
            </div>
          </div>
          {
            props.ticket.freeticket!=0 && (
            <>
          <div style={{marginTop:'15px'}} className='cart_item-sec-mobile d-flex justify-content-between shopping-cart-wrap align-items-center'>
          <div className='price-wrap'>
              <span className='price-new'>
                <span className='text-muted font-weight-bold cart_item-sec-mobile-label d-block d-md-none mb-2 mt-3'>
                  {t('Price')}
                </span>
                €0,00
              </span>
            </div>
            <div style={{ maxWidth: '80px', minWidth: '80px' , marginLeft:'17px' }}>
              <span className='text-muted font-weight-bold cart_item-sec-mobile-label d-block d-md-none mb-2 mt-3'>
                {t('Quantity')}
              </span>
              <TicketCounter
                max={10}
                value={0}
                event={props.event}
                ticket={props.ticket}
                ticketLeftText={false}
                item={props.item}
                itemD = {props.itemD}
                disabled={true}
              />
            </div>
            <div className='price-wrap'>
              <span className='text-muted font-weight-bold cart_item-sec-mobile-label d-block d-md-none mb-2 mt-3'>
                {t('Subtotal')}
              </span>
              <var className='price notranslate'>{t('Free')}</var>
            </div>
            <div className='text-right'>
              <span className='text-muted font-weight-bold cart_item-sec-mobile-label d-block d-md-none mb-2 mt-3'>
                <FontAwesomeIcon icon={faCog} />
              </span>
              <span
               style={{pointerEvents:'none',color:'darkgray'}}
                className='link-alt'
                data-tip
                data-for='remove-icon'>
                <FontAwesomeIcon icon={faMinusSquare} />
              </span>
              
            </div>
        </div>
            </>
            )
          }
   
        </div> 
        <div className='table-responsive' style={{marginTop:'15px'}}>
        
{
   Object.keys(suplement).length>0 &&<>
   {
     Object.keys(suplement).map(item=>(  
    <>
{
  suplement[item].length!=0&&<>
  <table className='table table-hover table-condensed mb-1' >
    <tbody className='my-body'>
       <tr
         style={{
         width: '100%',
         backgroundColor: '#31c5f4',
         color: 'white'
         }}>
        <td className='my-row'>
          <h3 className='custom-font-size'>{t('TicketSuppliments')} ({(parseInt(item)+1)}/{props.item.quantity})</h3>
         </td>
       </tr>
    </tbody>
    </table>
    <table className='table table-hover table-condensed mb-1'>
          <thead>
            <tr>
              <th className='border-top-white'>{t('SupplementTitle')}</th>
              <th>{t('TitleQty')}</th>
              <th>{t('Subtotal')}</th>
            </tr>
          </thead>
          <tbody>
          {
            suplement[item].map(singleitem=>(
            <>
             {
                  (singleitem.type==1 || singleitem.type==3) &&
                  <tr className='bg-gray'>
                  <td className='border-top-white'>
                     <div style={{paddingLeft:'20px'}}>{singleitem.title} {singleitem.type == 3? '( '+singleitem.value+' )' : ''}</div>
                   </td>
                  
                   <td className='border-top-white'>
                     <div style={{paddingLeft:'15px'}}>1</div>
                   </td>
                     <td className='border-top-white'>
                     <div style={{paddingLeft:'15px'}}>€{singleitem.price}</div>
                   </td>
                 </tr>
                }
                 {
                  singleitem.type==2 &&
                  singleitem.value.map(newitem=>(
                  <>
                  <tr className='bg-gray'>
                  <td className='border-top-white'>
                     <div style={{paddingLeft:'20px'}}>{singleitem.title} ({newitem.title})</div>
                   </td>
                  
                   <td className='border-top-white'>
                     <div style={{paddingLeft:'15px'}}>1</div>
                   </td>
                     <td className='border-top-white'>
                     <div style={{paddingLeft:'15px'}}>€{newitem.price}</div>
                   </td>
                 </tr>
                  </>
                 ))       
                }

            </>
              ))
          }
          </tbody>
        </table>
</>
}
    </>
     ))
   }

</>
}

</div>
      </div>
    </div>
  );
}
export default CartItem;
