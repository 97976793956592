import React, { useState, useEffect } from "react";
import { faFacebookF, faGoogle } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Input from "../../sections/ui/formfields/Input";
import { faLock, faUser,faTicketAlt,faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Alert from "../../sections/ui/Alert";
import { useForm } from "react-hook-form";
import axios from 'axios';
import { API_DOMAIN } from './../../Config'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
import Popup from "../../sections/ui/Popup/Popup";
import { useHistory } from 'react-router-dom'
import {useTranslation} from "react-i18next";
import queryString from 'query-string';
import LabeledInput from '../../sections/ui/formfields/LabeledInput';
import RadioInput2 from '../../sections/ui/formfields/RadioInput2';
import { useAlert } from 'react-alert';
import DateInput from '../../sections/ui/formfields/LabededDateField';
import CustomSelect from '../../sections/ui/formfields/CustomSelect'
import moment from 'moment';

function Signin(props) {
  const {t, i18n} = useTranslation('common');
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [token, settoken] = useState('');
  const history = useHistory()
  const [reviewPopup, setReviewPopup] = useState(false);
  const [socialLoginId, setSocialLoginId] = useState({
    GoogleClientId: "",
    FacebookAppId: ""
  })
  const [IsError, setIsError] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [birthPlace, setBirthPlace] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [customerEmailConfirm, setCustomerEmailConfirm] = useState('');
  const [phoneCode, setPhoneCode] = useState('');
  const [clubCode, setClubCode] = useState('');
  const [birthDate, setBirthDate] = useState(new Date())
  const [invoiceselect, SetInvoiceSelect] = useState({
    label: t('No'),
    value: 0
  });
  const [invoiceDetail, setInvoiceDetail] = useState('')
  const [customerPopup, setCustomerPopup] = useState(false);
  const [isIphone, setIsIphone] = useState(false);
  const alert = useAlert();


  const [formData, setformData] = useState({
    email: '',
    first_name: '',
    last_name: '',
    role_id: ''
  })

  const onSubmit = (formData) => {
    setIsError('');
    checkUserAUthentication(formData);
  }

  const GetSocialLoginId = async () => {
    try {
      await axios({
        url: API_DOMAIN + 'api/settings',
        method: 'GET',
      }).then((response) => {
        const data = response.data.data;
        let FacebookAppId_Key = data.find(x => x.key === 'apps.facebook_app_id');
        let GoogleClientId_Key = data.find(x => x.key === 'apps.google_client_id');
        setSocialLoginId({
          GoogleClientId: GoogleClientId_Key.value,
          FacebookAppId: FacebookAppId_Key.value
        })

      })
    }
    catch {

    }
  }

  useEffect(() => {
    GetSocialLoginId();
    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      setIsIphone(true)
    } 
  }, [])

  useEffect(()=> {
    const parsed = queryString.parse(props.location.search);
    if(parsed){
      if(parsed.id !==""){
      checkUserAUthenticationAtPageLoad(parsed.id, parsed.verify_string);
      }
    }
  }, []);

  const checkUserAUthenticationAtPageLoad = async (idd, verifyString) => {

    try {
      await axios({
        url: API_DOMAIN + 'api/auth/verify_admin_customer',
        method: 'POST',
        params: {
          id: idd,
          verify_string: verifyString
        }
      }).then(function (res) {
        if (res.data.success) {
          localStorage.setItem('user', JSON.stringify(res.data.data))
          localStorage.setItem('IsAdminuser', 'true')
          localStorage.removeItem("customer_name");
          localStorage.removeItem("customer_phone");
          localStorage.removeItem("customer_email")
          localStorage.removeItem("customer_club");
          localStorage.removeItem("customer_birth");
          localStorage.removeItem("customer_birthplace");
          localStorage.removeItem("customer_invoice");
          localStorage.removeItem("customer_invoicedetail");
          settoken(res.data.data.access_token);
          let search = window.location.search;
          let params = new URLSearchParams(search);
          let redirect = params.get('redirect');
          if (redirect) {
            window.location.href = redirect;
          }
          else if (res.data.data.user_detail.role_id == 2) {
            window.location.href = "/dashboard/attendee/my-tickets";
          }
          else {
            window.location.href = "/dashboard/organizer/";
          }
        }
      });
    } catch (error) {
      
    }
  };
  
  const Handler = ()=>{
    if (firstName && lastName && phoneCode && clubCode && birthDate && birthPlace && customerEmail  && (invoiceselect.value==0 || (invoiceselect.value==1 && invoiceDetail)) && (customerEmail==customerEmailConfirm)) {
      localStorage.setItem("customer_name",`${firstName}_${lastName}`);
      localStorage.setItem("customer_phone",phoneCode);
      localStorage.setItem("customer_email",customerEmail)
      localStorage.setItem("customer_club",clubCode);
      localStorage.setItem("customer_birthplace",birthPlace);
      localStorage.setItem("customer_birth",moment(birthDate).format('YYYY-MM-DD'));
      localStorage.setItem("customer_invoice",(invoiceselect.value==1?"Yes":"No"));
      localStorage.setItem("customer_invoicedetail",invoiceDetail);
      history.push('/dashboard/attendee/checkout');
    }
    else
    {
      window.alert(t('fillallfields'))
    }
  }

  const checkUserAUthentication = async (formData) => {
    try {
      await axios({
        url: API_DOMAIN + 'api/auth/login',
        method: 'POST',
        params: {
          email: formData.email,
          password: formData.password
        }
      }).then(function (res) {
        if (res.data.success) {
          localStorage.setItem('user', JSON.stringify(res.data.data))
          localStorage.removeItem("customer_name");
          localStorage.removeItem("customer_phone");
          localStorage.removeItem("customer_email")
          localStorage.removeItem("customer_club");
          localStorage.removeItem("customer_birth");
          localStorage.removeItem("customer_birthplace");
          localStorage.removeItem("customer_invoice");
          localStorage.removeItem("customer_invoicedetail");
          settoken(res.data.data.access_token);
          let search = window.location.search;
          let params = new URLSearchParams(search);
          let redirect = params.get('redirect');
          if (redirect) {
            window.location.href = redirect;
          }
          else if (res.data.data.user_detail.role_id == 2) {
            window.location.href = "/dashboard/attendee/my-tickets";
          }
          else {
            window.location.href = "/dashboard/organizer/";
            // if (res.data.data.user_detail.status == 1) {
            //   window.location.href = "/dashboard/organizer/";
            // }
            // else {
            //   setIsError('Profile approval pending from Admin. Please try later.');
            // }
          }
        }
      });
    } catch (error) {
      setIsError(t('UserNotFoundTryAgain'));
    }
  };

  const TicketsPopupClick = () => {
    setReviewPopup(!reviewPopup);
  };

  const facebookLogin = (response) => {
    const fbData = response;
    if (response.email != undefined) {
      const headers = {
        accept: 'application/json'
      }
      const facebookData = { email: response.email }
      try {
        axios.post(API_DOMAIN + 'api/auth/check_user_exist', facebookData, { headers }).then(response => {
          const data = response.data.data;
          if (data.length == 0) {
            setformData({
              email: fbData.email,
              first_name: fbData.name,
              last_name: '',
              role_id: ''
            })
            setReviewPopup(!reviewPopup);
          }
          else {
            if (response.data.success) {
              localStorage.setItem('user', JSON.stringify(response.data.data))
              localStorage.removeItem("customer_name");
              localStorage.removeItem("customer_phone");
              localStorage.removeItem("customer_email")
              localStorage.removeItem("customer_club");
              localStorage.removeItem("customer_birth");
              localStorage.removeItem("customer_birthplace");
              localStorage.removeItem("customer_invoice");
              localStorage.removeItem("customer_invoicedetail");
              settoken(response.data.data.access_token);
              let search = window.location.search;
              let params = new URLSearchParams(search);
              let redirect = params.get('redirect');
              if (redirect) {
                window.location.href = redirect;
              }
              else if (response.data.data.user_detail.role_id == 2) {
                window.location.href = "/dashboard/attendee/my-tickets";
              }
              else {
                window.location.href = "/dashboard/organizer/";
              }
            }
          }
        })
      }
      catch (error) {
      }
    }
  }



  const responseGoogle = (response) => {
    const profileObj = response.profileObj
    if (response.profileObj != undefined) {

      const headers = {
        accept: 'application/json'
      }
      const googleData = { email: profileObj.email }
      try {
        axios.post(API_DOMAIN + 'api/auth/check_user_exist', googleData, { headers }).then(response => {
          const data = response.data.data;
          if (data.length == 0) {
            setformData({
              email: profileObj.email,
              first_name: profileObj.givenName,
              last_name: profileObj.familyName,
              role_id: ''
            })
            setReviewPopup(!reviewPopup);
          }
          else {
            if (response.data.success) {
              localStorage.setItem('user', JSON.stringify(response.data.data))
              localStorage.removeItem("customer_name");
              localStorage.removeItem("customer_phone");
              localStorage.removeItem("customer_email")
              localStorage.removeItem("customer_club");
              localStorage.removeItem("customer_birth");
              localStorage.removeItem("customer_birthplace");
              localStorage.removeItem("customer_invoice");
              localStorage.removeItem("customer_invoicedetail");
              settoken(response.data.data.access_token);
              let search = window.location.search;
              let params = new URLSearchParams(search);
              let redirect = params.get('redirect');
              if (redirect) {
                window.location.href = redirect;
              }
              else if (response.data.data.user_detail.role_id == 2) {
                window.location.href = "/dashboard/attendee/my-tickets";
              }
              else {
                window.location.href = "/dashboard/organizer/";
              }
            }
          }
        })
      }
      catch (error) {
      }
    }
  }

  const handleAttendeeClick = async () => {
    const headers = {
      accept: 'application/json'
    }
    const attendeeData = { email: formData.email, first_name: formData.first_name, last_name: formData.last_name, role_id: 2 }

    try {
      await axios.post(API_DOMAIN + 'api/auth/social_login', attendeeData, { headers }).then(response => {
        localStorage.setItem('user', JSON.stringify(response.data.data))
        localStorage.removeItem("customer_name");
        localStorage.removeItem("customer_phone");
        localStorage.removeItem("customer_email")
        localStorage.removeItem("customer_club");
        localStorage.removeItem("customer_birth");
        localStorage.removeItem("customer_birthplace");
        localStorage.removeItem("customer_invoice");
        localStorage.removeItem("customer_invoicedetail");
        settoken(response.data.data.access_token);
        window.location.href = "/dashboard/attendee/my-tickets";
      })
    }
    catch (error) {
    }
  }
  const handleOrganizerClick = async () => {
    const headers = {
      accept: 'application/json'
    }
    const organizerData = { email: formData.email, first_name: formData.first_name, last_name: formData.last_name, role_id: 3 }
    try {
      await axios.post(API_DOMAIN + 'api/auth/social_login', organizerData, { headers }).then(response => {
        localStorage.setItem('user', JSON.stringify(response.data.data))
        localStorage.removeItem("customer_name");
        localStorage.removeItem("customer_phone");
        localStorage.removeItem("customer_email")
        localStorage.removeItem("customer_club");
        localStorage.removeItem("customer_birth");
        localStorage.removeItem("customer_birthplace");
        localStorage.removeItem("customer_invoice");
        localStorage.removeItem("customer_invoicedetail");
        settoken(response.data.data.access_token);
        window.location.href = "/dashboard/organizer/";
      })
    }
    catch (error) {
    }
  }
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let redirectURL = params.get('redirect');

  return (

    <section
      className="section-content padding-y bg-white"
      style={{ borderTop: "1px solid #f1f3f7" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-4 offset-lg-4">
            <div className="col-12 mb-5">
              <h1 className="card-title text-center">{t('SignInPage.SignInButton')}</h1>
              <p className="card-title text-center">{t('SignInSUbTitle')}</p>
            </div>
            <p>

              <Popup
                className="modal-dialog-scrollable"
                openModal={reviewPopup}
                closeModal={TicketsPopupClick}
                title={
                  <>
                    {t('SignInAs')}
                  </>
                }
              >
                <div>
                  <button className="btn btn-success" onClick={() => handleAttendeeClick()}>{t('Header.Attendee')}</button>
                  <button className="btn btn-success" onClick={() => handleOrganizerClick()}>{t('Header.Organizer')}</button>
                </div>
              </Popup>
              {
                socialLoginId.FacebookAppId != "" ?
                  <FacebookLogin
                    appId={socialLoginId.FacebookAppId}
                    callback={facebookLogin}
                    fields="name,email"
                    disableMobileRedirect={true}
                    isMobile={false}
                    render={renderProps => (
                      <button
                        onClick={renderProps.onClick}
                        className="btn btn-block btn-facebook"
                      >
                        <FontAwesomeIcon icon={faFacebookF} /> &nbsp; {t('SignInPage.FBLogin')}
                      </button>
                    )}
                  /> : null
              }

              {
                socialLoginId.GoogleClientId != "" ?
                  <GoogleLogin
                    clientId={socialLoginId.GoogleClientId}
                    render={renderProps => (                  
                      <button
                        onClick={renderProps.onClick}
                        href="/connect/google"
                        className="btn btn-block btn-google-plus"
                      >
                        <FontAwesomeIcon icon={faGoogle} /> &nbsp; {t('SignInPage.GoogleLogin')}
                      </button>
                     
                    )}
                    buttonText="Login"
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    cookiePolicy={'single_host_origin'}
                  /> : null
              }
              
              {
                (socialLoginId.GoogleClientId != "" && isIphone) && <>
                <p className="warning">{t('SignInPage.googlewarning')}</p> 
                </>
              }
             
            </p>
            <p className="divider-text">
              <span>{t('SignUpPage.OR')}</span>
            </p>
            <form className="for-form" onSubmit={handleSubmit(onSubmit)}>
              {IsError ?
                <Alert type="error" children={IsError} />
                :
                null
              }
              <div className={`form-group input-group ${errors.email && errors.email.type === "required" && 'error'}`}>
                <div className="input-group-prepend">
                  <span className="input-group-text border-0"><FontAwesomeIcon icon={faEnvelope} /></span>
                </div>
                <input
                  id="email"
                  className={`form-control `}
                  name="email"
                  placeholder={t('SignInPage.EmailAddress')}
                  type="text"
                  {...register('email', { required: true, maxLength: 50 })}
                  autoComplete="off"
                />

              </div>
              <div className={`form-group input-group ${errors.password && errors.password.type === "required" && 'error'}`}>
                <div className="input-group-prepend">
                  <span className="input-group-text border-0"><FontAwesomeIcon icon={faLock} /></span>
                </div>
                <input
                  id="password"
                  className={`form-control`}
                  name="password"
                  placeholder={t('SignInPage.Password')}
                  type="password"
                  {...register('password', { required: true, maxLength: 200, minLength: 3 })}
                  autoComplete="off"
                />
              </div>
             
              <div className="form-group">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="remember_me"
                    name="_remember_me"
                    value="on"
                  />
                  <label className="custom-control-label" htmlFor="remember_me">
                  {t('SignInPage.RememberMe')}
                  </label>
                </div>
              </div>
              <div className="form-group">
                <button
                  type="submit"
                  name="_submit"
                  className="btn btn-primary btn-block"
                >
                  {t('SignInPage.SignInButton')}
                </button>
                {(params.get('redirect')=='/dashboard/attendee/checkout') &&
                  <>
                   <div className="py-3 d-flex align-items-center">
        <hr className="flex-grow-1" />
        <div className="badge">OR</div>
        <hr className="flex-grow-1" />
    </div>
    <button
                  type='button'
                  name='_guestuser'
                  onClick={() =>
                    {
                      setCustomerPopup(true)
                    }}
                  className='btn btn-primary btn-block'>
                  {t('guestuser')}
                </button>
                  </>
                }
              </div>
              <p className="text-center">
                <a href="/en/resetting/request"></a>
                <Link to="/ForgotPassword">{t('SignInPage.ForgotYourPassword')} ?</Link>
              </p>
              <p className="text-center big-font">
              {t('SignInPage.NotAMemberYet')} ? &nbsp;
                <Link to={`/signup/attendee?redirect=${redirectURL}`} className="text-primary_600">
                {t('SignInPage.SignUpLink')}
                </Link>
              </p>
            </form>
          </div>
        </div>
      </div>
      <Popup
        className='modal-lg modal-dialog-scrollable'
        openModal={customerPopup}
        closeModal={() => setCustomerPopup(false)}
        title={
          <>
            <FontAwesomeIcon icon={faTicketAlt} className='mr-1' />
            {t('guestuser')}
          </>
        }
        footer={
          <>
            <span className='close'></span>
            <span>
              {t('SendButton')}
            </span>

          </>
        }
        footerAction={Handler}
      >
        <>
        <div>
        <div className='row'>
                          <div className='col-12 col-md-12'>
                            <LabeledInput
                              label={t('PopupPage.FirstName')}
                              id='firstName'
                              name='firstName'
                              value={firstName}
                              onChange={e => {
                               setFirstName(e.target.value)
                              }}
                              required
                            />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-12 col-md-12'>
                            <LabeledInput
                              label={t('PopupPage.LastName')}
                              id='lastName'
                              name='lastName'
                              value={lastName}
                              onChange={e => {
                               setLastName(e.target.value)
                              }}
                              required
                            />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-12 col-md-12'>
                            <LabeledInput
                              label={t('PopupPage.CustomerEmail')}
                              id='customerEmail'
                              name='customerEmail'
                              value={customerEmail}
                              onChange={e => {
                                setCustomerEmail(e.target.value)
                               }}
                              required
                            />
                          </div>
                        </div> 
                        <div className='row'>
                          <div className='col-12 col-md-12'>
                            <LabeledInput
                              label={t('PopupPage.CustomerEmailConfirm')}
                              id='customerEmailConfirm'
                              name='customerEmailConfirm'
                              value={customerEmailConfirm}
                              onChange={e => {
                                setCustomerEmailConfirm(e.target.value)
                               }}
                              required
                            />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-12 col-md-12'>
                            <LabeledInput
                              label={t('PopupPage.BirthPlace')}
                              id='birthPlace'
                              name='birthPlace'
                              value={birthPlace}
                              onChange={e => {
                               setBirthPlace(e.target.value)
                              }}
                              required
                            />
                          </div>
                        </div>
                        <div className='row'>
                        <div className='col-12 col-md-12'>
                          <DateInput
                            label={t('PopupPage.BirthDate')}
                            type='text'
                            required
                            id='birth_date'
                            setStartDate={date => {
                              setBirthDate(date)
                            }}
                            startDate={birthDate}
                          />
                        </div>
                        </div>                
                        <div className='row'>
                          <div className='col-12 col-md-12'>
                            <LabeledInput
                              label={t('PopupPage.PhoneNumber')}
                              id='phoneCode'
                              name='phoneCode'
                              value={phoneCode}
                              type='tel'
                              onChange={e => {
                                setPhoneCode(e.target.value)
                               }}
                              required
                            />
                          </div>
                        </div>

                        <div className='row'>
                          <div className='col-12 col-md-12'>
                          <CustomSelect
                           className='custom_select-div'
                           required={true}
                           label={t('PopupPage.Invoice')}
                           options={[
                           {
                            label: t('Yes'),
                            value: 1
                          },
                          {
                           label: t('No'),
                           value: 0
                          }
                         ]}
                         name='invoice'
                         isClearable={false}
                         value={invoiceselect}
                         handleChange={e => {
                          SetInvoiceSelect(e)
                         }}
                           />
                          </div>
                        </div>
                        {
                        invoiceselect.value==1 && <>
                           <div className='row'>
                        <div className='col-12 col-md-12'>
                        <LabeledInput
                        textArea
                        label={t('PopupPage.InvoiceDetail')}
                        type='text'
                        id='invoice_detail'
                        name='invoice_detail'
                        value={invoiceDetail}
                        onChange={e => {
                          setInvoiceDetail(e.target.value)
                         }}
                        required
                      />
                      </div>
                      </div></>
                        }   
                         <div className='row'>
                          <div className='col-12 col-md-12'>
                            <LabeledInput
                              label={t('PopupPage.Club')}
                              id='club_id'
                              name='club_id'
                              value={clubCode}
                              onChange={e => {
                                setClubCode(e.target.value)
                               }}
                              required
                            />
                          </div>
                        </div>
                        </div>
        </>
      </Popup>
    </section>
  );
}

export default Signin;
